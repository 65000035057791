.o-camera {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: $black;
    z-index: 1;

    &_back {
        position: fixed;
        z-index: 10;
        top: rem(24);
        left: rem(24);
        cursor: pointer;
    }

    &_image {
        width: 100%;
        height: 100%;
        padding-bottom: 120/270 * 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: rem(270);
        overflow: hidden;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &_video {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $black;
        //transform: translate(-50%, -50%);
        transform: translate(-50%, -50%) scaleX(-1);

        object-fit: fill;
        width: 100%;
        height: 100%;

        &-flip {
            transform: translate(-50%, -50%) scaleX(-1);
        }
    }

    canvas {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
    }

    &_capture {
        position: absolute;
        bottom: rem(65);
        z-index: 1;
        transform: translateX(-50%);
        overflow: hidden;
        left: 50%;

        &-button {
            border-radius: 50%;
            border: rem(6) solid $white;
            overflow: hidden;
            width: rem(60);
            margin: auto;
            @include adjust-flex();


            &:hover,
            &:focus {
                opacity: 0.8;
            }

            .a-button {
                width: rem(48);

                &:not(:disabled) {
                    &:hover,
                    &:focus {
                        opacity: 1;
                    }
                }

                &_icon {
                    margin-right: 0;
                }
            }
        }
    }

    &_capturePreview {
        position: absolute;
        bottom: rem(65);
        z-index: 1;
        transform: translateX(-50%);
        overflow: hidden;
        left: 50%;
        @include adjust-flex();

        &-button {
            overflow: hidden;
            margin: 0 rem(4);
            @include adjust-flex();

            &:hover,
            &:focus {
                opacity: 0.8;
            }

            .a-button {
                min-width: rem(120);

                &:not(:disabled) {
                    &:hover,
                    &:focus {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &_submit {
        position: absolute;
        bottom: rem(60);
        right: rem(16);
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    &_confirm {
        &_text {
            margin-top: rem(24);
        }

        &_buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: rem(16);
            margin-top: rem(32);

            .a-button {
                max-width: rem(136);
                margin-left: rem(8);
                margin-right: rem(8);
            }
        }
    }

    &_preview {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 9;
        background-size: contain;
        background-position: center;
        background-color: $black;
        background-repeat: no-repeat;
    }

    &_loading {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 10;
        background-color: rgba($white, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: rem(48);
        }
    }
}
