.a-checkbox {
    $root: &;
    height: 100%;

    &_label {
        @include adjust-flex(space-between);
        cursor: pointer;
        padding: rem(10) rem(16);
        position: relative;
        border: 1px solid $manatee;
        border-radius: rem(4);
        height: 100%;
    }

    &-italic,
    &-normal {
        #{$root} {
            &_label {
                padding: 0;
                border: 0;
                display: inline-flex;
                padding-left: rem(3);
                flex-direction: row-reverse;
            }

            &_checkMark {
                margin-right: rem(7);
            }
        }
    }

    &_link {
        white-space: normal;
        font-weight: 500;
        text-decoration: underline;
        color: $blue-crayola;
        @include font-base(14, 20);
        margin-left: rem(8);
    }

    &-italic {
        #{$root} {
            &_label {
                font-style: italic;
            }
        }
    }

    input {
        &:checked ~ #{$root}_checkMark {
            border-color: $la-salle-green;

            &::after {
                display: block;
            }
        }
    }

    &_text {
        @include font-base(14, 20);
        white-space: normal;
        font-weight: 500;
        color: $eerie-black;
        word-break: break-word;
    }

    &_checkMark {
        height: rem(14);
        width: rem(14);
        border-radius: rem(3);
        border: 1px solid $manatee;
        position: relative;
        flex-shrink: 0;

        &::after {
            content: '';
            position: absolute;
            display: none;
            left: rem(3);
            bottom: rem(3);
            width: rem(3.5);
            height: rem(7);
            border: solid $la-salle-green;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
